<template>
  <div>
    <div class="d-flex">
      <!--     <h3 class="mr-3" style="padding: 10px 26px; height: 53px; ">-->
      <!--       {{ $t('notice.title') }}-->
      <!--     </h3>-->
      <v-btn
          v-if="noticeCan('create')"
          style="padding: 27px 16px"
          elevation="2"
          color="primary"
          medium
          class="mr-3"
          :to="{name: 'notices.create', params: {customer_id: customer_id}}"
      >
        <v-icon size="20" class="mr-1">mdi-plus</v-icon>
        {{ $t('notice.btn.create') }}
      </v-btn>
        <v-text-field
            v-if="getNotices.length > 0"
            v-model="search"
            append-icon="mdi-magnify"
            :label=" $t('notice.table.search')"
            hide-details
            solo
            class="mb-6 elevation"
            height="53"
        />
    </div>
    <template v-if="getNotices.length > 0">
      <v-data-table
          :headers="headers"
          :items="notices"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :search="search"
          :page.sync="page"
          hide-default-footer
      >
        <template v-slot:item.text="{ item }">
          <!--       <div style="background: #fffbc7; padding: 16px" v-html="brs(item.text)">-->
          <div style="background: #fffce6; padding: 16px" v-html="brs(item.text)">
            {{item.text}}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-html="brs(item.created_at)"></div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn v-if="isMy(item) && noticeCan('update')" text elevation="0" small :to="{name: 'notices.edit', params: {id: item.id}}">
            <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="pt-2 d-flex justify-center">
        <div class="ml-auto">
          <v-pagination
              v-model="page"
              :length="pageCount"
          ></v-pagination>
        </div>
        <div class="ml-auto" style="max-width: 60px;">
          <v-text-field
              :value="itemsPerPage"
              label=""
              type="number"
              min="-1"
              max="10"
              @input="itemsPerPage = parseInt($event, 10)"

          ></v-text-field>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-center justify-center" style="min-height: 560px;">
        <h3>{{ $t('notice.table.no_data_available') }}</h3>
      </div>
    </template>

  </div>
</template>

<script>
import {can} from "../middleware/index";

export default {
  name: "NoticeList",
  props: {
    notices: {
      type: Array,
      default: () => []
    },
    customer_id: {
      default: null
    }
  },
  data() {
    return {
      search: '',
      headers:[
        { text: this.$t('notice.table.customer'), value: 'customer', width: '200px' },
        { text: this.$t('notice.table.text'), value: 'text' },
        { text: this.$t('notice.table.created_at'), value: 'created_at' },
        // { text: this.$t('notice.table.create_at'), value: 'created_at', width: '200px' },
        // { text: this.$t('notice.table.update_at'), value: 'updated_at', width: '200px' },
        { text: '', value: 'action',sortable: false, width: '150px' },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    }
  },
  computed: {
    getNotices() {
      return this.notices
    }
  },
  methods: {
    noticeCan(per) {
      return can(per)
    },
    isMy(item) {
      return item.employee_id === this.$store.getters.user.uid
    },
    brs(text) {
      if (text !== null) {
        return  text.replace(/\n/g, '<br />')
      }
      return text
    },

  }
}
</script>