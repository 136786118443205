<template>
  <div style="position:relative; height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
<!--      <div class="notice-top_menu mt-1 mb-6">-->
<!--        <h3>{{ $t('notice.title') }}</h3>-->
<!--      </div>-->
      <NoticeList :notices="notices" />
    </v-container>
  </div>
</template>

<script>
import NoticeList from "@/module/notice/components/NoticeList";

export default {
  components: {NoticeList},
  data() {
    return {
      preloader:true,
      notices: []
    }
  },
  created() {
    this.getAllNotices()
  },
  methods: {
    async getAllNotices() {
      let customers = this.$store.getters.companies.data
      if (undefined === customers) {
        await this.$store.dispatch('companies', 1).then(() => {
          customers = this.$store.getters.companies.data
        })
      }
      await this.$store.dispatch('notices', 1).then(() => {
        this.notices = this.$store.getters.notices
        this.notices = this.notices.map(notice => {

          let customer = customers.find(customer => customer.customer_id === notice.customer_id)

          if (undefined !== customer) {
            // let cm = customer.customer
            // n.customer = cm.first_name + ' ' + cm.last_name
            notice.customer = customer.company_name
          }

          return notice;
        })

        this.preloader = false
      })
    }
  }
}
</script>
